import { checkWindow } from "@vfit/shared/data-access";
export var APP_SESSION_MOCK = {
    firstname: "COMBINATION",
    fiscalcode: "XBSCBN90A07F205L",
    status: "authenticated",
    installationId: "8e02d6b1d6314c1d86ea3ddcc732d046",
    type_sim: "ricaricabile",
    osVersion: "13",
    profile: "A00",
    appVersion: "13.1.0",
    appPlatform: "Android",
    value: "3444709337",
    type: "sim",
    sessionId: "ZAsFe7wZ7ShP3caiS239nzkAtvG7z9Z1",
    mid: "29148987287202002034238077340243094341",
    secretKey: "",
    token: "eyJhbGciOiJSUzI1NiJ9.eyJzZXNzaW9uX2lkIjoiWkFzRmU3d1o3U2hQM2NhaVMyMzluemtBdHZHN3o5WjEiLCJzdWIiOiJ2b2RhZm9uZS1keGwiLCJpc3MiOiJqcy1sb2dpbiIsImF1ZCI6ImF1ZGllbmNlX3ZhbHVlIiwiZXhwIjoxNjc4NDQ3NDk5fQ.H3aD5b8E8sjK8ZZ4AwZJU0zjbHzRLhtwozy4XkmmeUSutIfr7Q5ulu23YKlyBk_hia41r7MTL13WfiMYBOBxYuGGW7OEFzG2xvUmbKD9nyf6phjD-aXSpdxMdcw2quX8PJhtnjOM9UUKL3Ghnv-QKPZpEUNVx_I8ck5_092_K2_u0FXhcL6YgTEQJlDIoQsPQ6uCMowgnMBlZ0ZBNJizIjmx6B8jG0RxStRyEvuNWAR3rc3wfGnUcnBVzLRfZY9GPWuwtnjnBACtkGFwQKETelnKRfjf_bp_oMyughznrqjpDbzs3yM0tHQAMz3ElWZGMOHRft4wRHDJjy23tNIBDA",
    customerUserAgent: 'MyVodafone/android/s/13/13.1.0/2.75/custom/"Google"/"sdk_gphone64_arm64"',
    pbo: "rt4Gm8uW%2FpQwlGh5%2BSAEWQ%3D%3D",
    isOverlay: true,
    isNext: true,
    role: ""
};
export var bwjExecuteSetupViewConfiguration = function() {
    var ref;
    if (!checkWindow()) return;
    var is = function(type, val) {
        return ![
            undefined,
            null
        ].includes(val) && val.constructor === type;
    };
    var serialize = function(params) {
        return Object.keys(params).map(function(key) {
            var item = params[key];
            if (is(Array, item) || is(Object, item)) {
                item = JSON.stringify(item);
            }
            return "".concat(key, "=").concat(encodeURIComponent(item));
        }).join("&");
    };
    var param = {
        parameters: {
            hideTOBi: true,
            fullNoHeader: true
        }
    };
    (ref = window.BWB_JS) === null || ref === void 0 ? void 0 : ref.execute("setupViewConfiguration", serialize(param), null);
};
