import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { checkIsGoBackSIA, useBillDelivery, useCreateAddress, useReplaceBillingAddressV1, useValidateAddress } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
var ENABLE_REPLACE_LOGIC = false;
var DEFAULT_DELIVERY_BILLING = {
    validateAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    createAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    replaceAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    billingPaper: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    billingEbill: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var useDeliveryBillingFlow = function(param) {
    var billingAddress = param.billingAddress, billingEmail = param.billingEmail, enableBillDelivery = param.enableBillDelivery;
    var ref = useState(DEFAULT_DELIVERY_BILLING), billingReturn = ref[0], setBillingReturn = ref[1];
    var ref1 = useBillDelivery(), billDeliveryEbillMutation = ref1.mutateAsync, isLoadingEBillDelivery = ref1.isLoading, isSuccessEBillDelivery = ref1.isSuccess, isErrorEBillDelivery = ref1.isError;
    var ref2 = useBillDelivery(), billDeliveryMutation = ref2.mutateAsync, isLoadingBillDelivery = ref2.isLoading, isSuccessBillDelivery = ref2.isSuccess, isErrorBillDelivery = ref2.isError;
    var ref3 = useValidateAddress(), validateAddressMutation = ref3.mutate, validatedShippingAddress = ref3.data, validateAddressIsLoading = ref3.isLoading, validateAddressIsSuccess = ref3.isSuccess, validateAddressIsError = ref3.isError;
    var ref4 = useCreateAddress(), createAddressMutation = ref4.mutate, createAddressData = ref4.data, isLoadingCreateAddressMutation = ref4.isLoading, isSuccessCreateAddressMutation = ref4.isSuccess, isErrorCreateAddressMutation = ref4.isError;
    var ref5 = useReplaceBillingAddressV1(), replaceBillingAddressMutation = ref5.mutate, isLoadingReplace = ref5.isLoading, isSuccessReplace = ref5.isSuccess, isErrorReplace = ref5.isError;
    var createBillingAddress = function(billingAddressCreatedOrValidated) {
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var billingAccountId = billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id;
        if (ENABLE_REPLACE_LOGIC) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    },
                    billingEbill: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            replaceBillingAddressMutation({
                billingAccountId: billingAccountId,
                billingAddressId: billingAddressCreatedOrValidated.id,
                billingAddressType: "postalAddress"
            });
        } else {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    },
                    replaceAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            billDeliveryMutation({
                billFormat: "paperBill",
                physicalAddress: billingAddressCreatedOrValidated
            });
        }
    };
    useEffect(function() {
        if (isLoadingEBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingEbill: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorEBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingEbill: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessEBillDelivery && !checkIsGoBackSIA()) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingEbill: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
        }
    }, [
        isSuccessEBillDelivery,
        isErrorEBillDelivery,
        isLoadingEBillDelivery
    ]);
    useEffect(function() {
        if (isLoadingBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessBillDelivery && !checkIsGoBackSIA()) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            billDeliveryEbillMutation({
                billFormat: "eBill",
                emailAddress: billingEmail
            });
        }
    }, [
        isSuccessBillDelivery,
        isErrorBillDelivery,
        isLoadingBillDelivery
    ]);
    useEffect(function() {
        if (isLoadingReplace) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    replaceAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorReplace) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    replaceAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessReplace) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    replaceAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
        }
    }, [
        isSuccessReplace,
        isLoadingReplace,
        isErrorReplace
    ]);
    useEffect(function() {
        if (isLoadingCreateAddressMutation) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorCreateAddressMutation) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessCreateAddressMutation) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            createBillingAddress(createAddressData);
        }
    }, [
        isSuccessCreateAddressMutation,
        isLoadingCreateAddressMutation,
        isErrorCreateAddressMutation, 
    ]);
    useEffect(function() {
        if (validateAddressIsLoading) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (validateAddressIsError) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (validateAddressIsSuccess && !checkIsGoBackSIA()) {
            var ref;
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            createAddressMutation({
                address: validatedShippingAddress === null || validatedShippingAddress === void 0 ? void 0 : (ref = validatedShippingAddress.validAddressList) === null || ref === void 0 ? void 0 : ref[0],
                isDelivery: true
            });
        }
    }, [
        validateAddressIsSuccess,
        validateAddressIsLoading,
        validateAddressIsError
    ]);
    useEffect(function() {
        if (!checkIsGoBackSIA() && enableBillDelivery && (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street) && (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city) && !validateAddressIsLoading) {
            validateAddressMutation({
                placeObject: billingAddress
            });
        }
    }, [
        billingAddress,
        enableBillDelivery
    ]);
    return billingReturn;
};
