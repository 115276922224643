import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  width: 287px;\n\n  @media (min-width: ",
        ") {\n    width: 366px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 406px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 2rem 0 1rem;\n  font-family: ",
        ";\n  text-align: center;\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    padding: 3.75rem 0 1rem;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n  text-align: center;\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  margin: 0 0 ",
        ";\n  font-size: 14px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n\n  div {\n    margin-top: 0;\n  }\n\n  p {\n    position: relative;\n  }\n\n  gap: ",
        ";\n\n  @media (min-width: ",
        ") {\n    gap: 20px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n  display: flex;\n  flex-direction: row;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  text-align: start;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 25px;\n  margin-right: 10px;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  input {\n    position: absolute;\n    margin: 0 auto;\n    opacity: 0;\n    cursor: pointer;\n    height: 20px;\n    width: 20px;\n    z-index: 3;\n  }\n\n  .checkmark::after {\n    left: 7px;\n    top: 3px;\n    width: 5px;\n    height: 10px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    content: '';\n    position: absolute;\n    display: none;\n  }\n\n  .checkmark {\n    position: absolute;\n    height: ",
        ";\n    width: ",
        ";\n    border-radius: ",
        ";\n    border: ",
        " solid ",
        ";\n  }\n\n  &:hover input ~ .checkmark {\n    border: ",
        " solid ",
        ";\n  }\n\n  input:checked ~ .checkmark {\n    background-color: ",
        ";\n    border: none;\n  }\n\n  input:checked ~ .checkmark::after {\n    display: block;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n\n  @media (min-width: ",
        ") {\n    gap: 28px;\n    margin-bottom: 4px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 12px;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  p {\n    margin: 0 0 8px;\n    color: ",
        ";\n  }\n\n  div {\n    justify-content: flex-start;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
var basicGap = "16px";
var doubleGap = "32px";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-29de7cbd-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-29de7cbd-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Text = styled.div.withConfig({
    componentId: "sc-29de7cbd-2"
})(_templateObject2(), fonts.regular, basicGap, doubleGap, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var TextALignLeft = styled(Text).withConfig({
    componentId: "sc-29de7cbd-3"
})(_templateObject3(), basicGap);
export var Div = styled.div.withConfig({
    componentId: "sc-29de7cbd-4"
})(_templateObject4(), basicGap, breakpoints.tablet);
export var Footer = styled.div.withConfig({
    componentId: "sc-29de7cbd-5"
})(_templateObject5(), fonts.regular, pxToCssFont(14, 18), doubleGap, doubleGap);
export var MobileFlowInputs = styled.div.withConfig({
    componentId: "sc-29de7cbd-6"
})(_templateObject6(), basicGap);
export var FooterDiv = styled.div.withConfig({
    componentId: "sc-29de7cbd-7"
})(_templateObject7());
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-29de7cbd-8"
})(_templateObject8());
export var Checkbox = styled.div.withConfig({
    componentId: "sc-29de7cbd-9"
})(_templateObject9(), pxToRem(20), pxToRem(20), pxToRem(3), pxToRem(1), colors.$bebebe, pxToRem(1), colors.$bebebe, colors.$00697c);
export var ReadOnlyDiv = styled.div.withConfig({
    componentId: "sc-29de7cbd-10"
})(_templateObject10(), breakpoints.tablet, breakpoints.desktop);
export var Field = styled.div.withConfig({
    componentId: "sc-29de7cbd-11"
})(_templateObject11(), fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 30), colors.$00697c);
