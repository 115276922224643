import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { checkIsGoBackSIA, useBillDelivery, useCreateAddress, useReplaceBillingAddressV1, useValidateAddress } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyFixed.context";
import { InvoiceType } from "../../components/InvoiceCard/components/ChooseInvoice/chooseInvoice.models";
var ENABLE_REPLACE_LOGIC = false;
var DEFAULT_DELIVERY_BILLING = {
    validateAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    createAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    replaceAddress: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    billDelivery: {
        isLoading: false,
        isSuccess: false,
        isError: false
    }
};
export var useDeliveryBillingFlow = function() {
    var ref;
    var ref1 = useCheckout(), invoice = ref1.invoice, billingAddress = ref1.billingAddress, customerFlow = ref1.customerFlow, billingEmail = ref1.billingEmail;
    var enableBillDelivery = customerFlow === null || customerFlow === void 0 ? void 0 : (ref = customerFlow.billingAccount) === null || ref === void 0 ? void 0 : ref.isSuccess;
    var ref2 = useState(DEFAULT_DELIVERY_BILLING), billingReturn = ref2[0], setBillingReturn = ref2[1];
    var ref3 = useBillDelivery(), billDeliveryMutation = ref3.mutateAsync, isLoadingBillDelivery = ref3.isLoading, isSuccessBillDelivery = ref3.isSuccess, isErrorBillDelivery = ref3.isError;
    var ref4 = useValidateAddress(), validateAddressMutation = ref4.mutate, validatedShippingAddress = ref4.data, validateAddressIsLoading = ref4.isLoading, validateAddressIsSuccess = ref4.isSuccess, validateAddressIsError = ref4.isError;
    var ref5 = useCreateAddress(), createAddressMutation = ref5.mutate, createAddressData = ref5.data, isLoadingCreateAddressMutation = ref5.isLoading, isSuccessCreateAddressMutation = ref5.isSuccess, isErrorCreateAddressMutation = ref5.isError;
    var ref6 = useReplaceBillingAddressV1(), replaceBillingAddressMutation = ref6.mutate, isLoadingReplace = ref6.isLoading, isSuccessReplace = ref6.isSuccess, isErrorReplace = ref6.isError;
    var createBillingAddress = function(billingAddressCreatedOrValidated) {
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var billingAccountId = billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id;
        if (ENABLE_REPLACE_LOGIC) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    },
                    billingEbill: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            replaceBillingAddressMutation({
                billingAccountId: billingAccountId,
                billingAddressId: billingAddressCreatedOrValidated.id,
                billingAddressType: "postalAddress"
            });
        } else {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billingPaper: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    },
                    replaceAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
        }
    };
    useEffect(function() {
        if (isLoadingReplace) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    replaceAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorReplace) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    replaceAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessReplace) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    replaceAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
        }
    }, [
        isSuccessReplace,
        isLoadingReplace,
        isErrorReplace
    ]);
    useEffect(function() {
        if (isLoadingCreateAddressMutation) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorCreateAddressMutation) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessCreateAddressMutation) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    createAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            createBillingAddress(createAddressData);
        }
    }, [
        isSuccessCreateAddressMutation,
        isLoadingCreateAddressMutation,
        isErrorCreateAddressMutation, 
    ]);
    useEffect(function() {
        if (isLoadingBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billDelivery: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (isErrorBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billDelivery: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (isSuccessBillDelivery) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    billDelivery: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
        }
    }, [
        isSuccessBillDelivery,
        isLoadingBillDelivery,
        isErrorBillDelivery
    ]);
    useEffect(function() {
        if (validateAddressIsLoading) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: true,
                        isSuccess: false,
                        isError: false
                    }
                });
            });
        } else if (validateAddressIsError) {
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: false,
                        isSuccess: false,
                        isError: true
                    }
                });
            });
        } else if (validateAddressIsSuccess) {
            var ref;
            setBillingReturn(function(prevState) {
                return _object_spread_props(_object_spread({}, prevState), {
                    validateAddress: {
                        isLoading: false,
                        isSuccess: true,
                        isError: false
                    }
                });
            });
            createAddressMutation({
                address: validatedShippingAddress === null || validatedShippingAddress === void 0 ? void 0 : (ref = validatedShippingAddress.validAddressList) === null || ref === void 0 ? void 0 : ref[0],
                isDelivery: true
            });
        }
    }, [
        validateAddressIsSuccess,
        validateAddressIsLoading,
        validateAddressIsError
    ]);
    useEffect(function() {
        if (!checkIsGoBackSIA()) {
            if (invoice === null || invoice === void 0 ? void 0 : invoice.option) {
                if (invoice.option === InvoiceType.EBILL) {
                    billDeliveryMutation({
                        billFormat: InvoiceType.EBILL,
                        emailAddress: billingEmail
                    });
                } else {
                    billDeliveryMutation({
                        billFormat: "paperBill",
                        physicalAddress: createAddressData
                    });
                }
            }
        }
    }, [
        invoice,
        billingEmail
    ]);
    useEffect(function() {
        if (enableBillDelivery && (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street) && (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city) && !validateAddressIsLoading && !checkIsGoBackSIA()) {
            validateAddressMutation({
                placeObject: billingAddress
            });
        }
    }, [
        billingAddress,
        enableBillDelivery
    ]);
    return billingReturn;
};
