import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect, useState } from "react";
import { useInterval } from "react-use";
function padZero(time) {
    return "".concat(time).length < 2 ? "0".concat(time) : "".concat(time);
}
export function getHMSTime(timeDiff) {
    if (timeDiff <= 0) {
        return [
            "00",
            "00",
            "00",
            "00"
        ];
    }
    var day = Math.floor(timeDiff / 86400);
    var hour = Math.floor(timeDiff % 86400 / 3600);
    var minute = Math.floor(timeDiff % 3600 / 60);
    var second = timeDiff % 60;
    return [
        padZero(day),
        padZero(hour),
        padZero(minute),
        padZero(second)
    ];
}
export var useCountDown = function(time) {
    var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : getHMSTime, callback = arguments.length > 2 ? arguments[2] : void 0;
    var ref = useState(time), remainTime = ref[0], setRemainTime = ref[1];
    var ref1 = useState(1000), delay = ref1[0], setDelay = ref1[1];
    useInterval(function() {
        if (remainTime <= 0) {
            setDelay(null);
            return;
        }
        setRemainTime(remainTime - 1);
    }, delay);
    useEffect(function() {
        if (time > 0 && remainTime <= 0) {
            callback && callback();
        }
    }, [
        callback,
        remainTime,
        time
    ]);
    var ref2 = _sliced_to_array(format(remainTime), 4), day = ref2[0], hour = ref2[1], minute = ref2[2], second = ref2[3];
    return [
        day,
        hour,
        minute,
        second
    ];
};
