import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 48px 44px;\n\n  @media (min-width: ",
        ") {\n    margin: 60px 40px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 72px 109px 40px;\n  }\n\n  .body {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 328px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 405px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    gap: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: 15px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 35px;\n    margin-bottom: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 31px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  font-family: ",
        ";\n  ",
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin: 0;\n  text-align: left;\n  font-weight: 400;\n  color: ",
        ";\n\n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  justify-content: center;\n\n  .button-slide-container {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  left: 40px;\n  top: 10px;\n  \n  svg {\n    width: 28px;\n    height: 25px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var ModalWrapper = styled.div.withConfig({
    componentId: "sc-d170d72c-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
export var ModalTextContainer = styled.div.withConfig({
    componentId: "sc-d170d72c-1"
})(_templateObject1(), breakpoints.desktop);
export var ModalBodyContainer = styled.div.withConfig({
    componentId: "sc-d170d72c-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var ModalTitle = styled.p.withConfig({
    componentId: "sc-d170d72c-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.tablet, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled.div.withConfig({
    componentId: "sc-d170d72c-4"
})(_templateObject4(), fonts.regular, pxToCssFont(20, 26), colors.$262626, breakpoints.tablet, pxToCssFont(18, 24));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-d170d72c-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var LoadingContainer = styled.div.withConfig({
    componentId: "sc-d170d72c-6"
})(_templateObject6());
